import { useEffect } from 'react';
import DownloadButton from 'components/DownloadButton';
import Recommendation from 'components/Recommendation';
import StoreRating from 'components/StoreRating';
import Disclaimer from 'components/Disclaimer';
import risksImage from 'assets/images/risks.png';
import risksImageEn from 'assets/images/risks-en.png';
import { useTranslation } from 'react-i18next';
import LogoutButton from 'components/LogoutButton';
import { useApi } from 'hooks/useApi';
import { useConfiguration } from 'providers/ConfigurationProvider';
import RenderNewLines from 'components/RenderNewLines';
import { TEST_ID } from '../../../constants';
import { useAuth } from 'providers/AuthProvider';

export default function HighRisk() {
  const { recommend } = useApi();

  useEffect(() => {
    if (!TEST_ID) {
      return;
    }

    recommend('suspectedHypertension');
  }, [recommend]);

  return <HighRiskGeneric />;
}

const HighRiskGeneric = () => {
  const { t, i18n } = useTranslation();
  const { configuration } = useConfiguration();
  const { session } = useAuth();

  return (
    <>
      <h1>{t('result_high_generic.heading')}</h1>
      <Recommendation risk="high">{t('recommendation.high')}</Recommendation>
      {configuration?.highRisk?.heading?.sv && <h2>{configuration.highRisk.heading[i18n.language]}</h2>}
      {configuration?.highRisk?.text?.sv && (
        <p>
          <RenderNewLines text={configuration.highRisk.text[i18n.language]} />
        </p>
      )}
      <DownloadButton text={t('global.get_started')} id="download-1" />
      <div className="button-explainer margin-offset">
        <small>{t('disclaimer.download')}</small>
      </div>
      <h2>{t('result_high_generic.sub_heading_2')}</h2>
      <ol>
        <li>
          <strong>{t('result_high_generic.list_item_1_1')}</strong> – {t('result_high_generic.list_item_1_2')}
        </li>
        <li>
          <strong>{t('result_high_generic.list_item_2_1')}</strong> – {t('result_high_generic.list_item_2_2')}
        </li>
        <li>
          <strong>{t('result_high_generic.list_item_3_1')}</strong> – {t('result_high_generic.list_item_3_2')}
        </li>
        <li>
          <strong>{t('result_high_generic.list_item_4_1')}</strong> – {t('result_high_generic.list_item_4_2')}
        </li>
      </ol>
      <h2>{t('result_high_generic.sub_heading_3')}</h2>
      <p>{t('result_high_generic.paragraph_5_1')}</p>
      <h3>{t('result_high_generic.sub_heading_3_1')}</h3>
      <p>
        <img src={i18n.language === 'sv' ? risksImage : risksImageEn} alt={t('result_high_generic.risks_image_alt')} />
      </p>
      <h2>{t('result_high_generic.sub_heading_4')}</h2>
      <h3>{t('result_high_generic.sub_heading_4_1')}</h3>
      <p>{t('result_high_generic.paragraph_4_1')}</p>
      <h3>{t('result_high_generic.sub_heading_4_2')}</h3>
      <p>{t('result_high_generic.paragraph_4_2')}</p>
      <DownloadButton text={t('global.get_started')} id="download-2" />
      <div className="button-explainer margin-offset mb-20">
        <small>{t('disclaimer.download')}</small>
      </div>
      <StoreRating />
      {session ? <LogoutButton marginTop={20} /> : null}
      <Disclaimer />
    </>
  );
};
